<template>
  <div>
    <div class="page-gradient">
      <section class="title container center">
        <h3>About Us</h3>
        <p>We're ready to give you the best product in the market.</p>
      </section>
      <div class="bottom-shape">
        <img src="~@/assets/images/page-shape.png" alt="" />
      </div>
    </div>
    <div class="about section-space">
      <div class="about-wrapper container">
        <div class="history flex">
          <div class="">
            <img src="~@/assets/images/about.jpg" alt="" />
          </div>
          <div class="history-txt">
            <section class="title container left">
              <h3>Our History</h3>
            </section>
            <p>
              Exclusive UX Designs, LLC is a family company in the technology
              industry with extensive experience in the design and development
              of fully customized websites and mobile applications for our
              valued customers. Since our company was created, five years ago,
              it has been developing top quality projects and making sure that
              the satisfaction of our customers is completely assured.
            </p>
            <div class="meter">
              <div class="meter-wrapper flex">
                <div>
                  <p>Year on Business</p>
                  <span>5+</span>
                </div>
                <div>
                  <p>Projects Completed</p>
                  <span>45+</span>
                </div>
                <div>
                  <p>Rating on Google</p>
                  <span>5.0</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="our-mission section-space flex">
      <div class="our-mission-wrapper container flex">
        <div class="mission-txt">
          <section class="title container left">
            <h3>Our Mission</h3>
          </section>
          <p>
            At Exclusive UX Designs we are dedicated exclusively to developing
            projects with the highest technology that can meet in all aspects
            the expectations of our customers. We plan as a mission, to provide
            complete web services starting from the table work to the
            development of the final product.
          </p>
          <p>
            Our team is dedicated to making people aware of the services and
            products you are offering, understand why your products or services
            are relevant and even necessary for them to buy. At Exclusive UX
            Designs, we understand that excellent web design is aesthetic
            because websites are often the first impression given to potential
            customers. We, therefore, develop great designs that create the
            perfect impression in the mind of your audience.
          </p>
        </div>
        <div class="">
          <img src="~@/assets/images/mission.png" alt="" />
        </div>
      </div>
    </div>
    <div class="skills section-space">
      <div class="skills-wrapper container flex">
        <div class="">
          <img src="~@/assets/images/skills.jpg" alt="" />
        </div>
        <div class="">
          <section class="title container left">
            <h3>Skills Deliver Results</h3>
          </section>
          <p>
            Over the years, responsiveness, usability, and simplicity have been
            the stellar features in mobile apps and website development. We know
            the techniques to use to draw attention from visitors and convert
            them to leads. At Exclusive UX Designs we help businesses unleash
            their full potentials through functional and modern website designs
            second to none.
          </p>
          <div class="skills-progress">
            <div class="progress-adobe">
              <div class="progress-bar"></div>
              <p>Adobe Photoshop</p>
              <span>90%</span>
            </div>
            <div class="progress-html">
              <div class="progress-bar"></div>
              <p>HTML5 / CSS3</p>
              <span>98%</span>
            </div>
            <div class="progress-develop">
              <div class="progress-bar"></div>
              <p>Development</p>
              <span>95%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'About'
}
</script>
<style>
@import '../assets/css/about.css';
</style>
